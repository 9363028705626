<template>
  <div
    class="card card-custom card-stretch p-0 pb-4"
    id="cardTimeline"
    @mouseleave="closeMenu"
  >
    <v-overlay :value="carregando" :absolute="true">
      <v-progress-circular indeterminate size="32" color="#356a7c" width="2"
        ><v-progress-circular
          indeterminate
          size="24"
          color="#ef720f"
          width="2"
          rotate="180"
        ></v-progress-circular
      ></v-progress-circular>
    </v-overlay>

    <perfect-scrollbar ref="scrollbar" style="height: 100%">
      <div class="card-body" @mouseleave="closeMenu">
        <div class="align-items-center border-0 mb-5">
          <div
            class="d-flex justify-content-between align-items-start flex-column"
          >
            <div
              class="d-flex justify-content-between align-items-center w-100"
            >
              <div>
                <div class="d-flex align-items-center">
                  <h5 class="m-0 mr-3">Timeline - 24h</h5>
                  <Tooltip
                    message="A Timeline de campanhas exibe em 24 horas envios, respostas, agendamentos e erros. Use filtros para selecionar mensagens desejadas. O sino alerta erros recentes. Reenvie mensagens com o botão que aparece sobre o erro"
                  />
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <i
                  class="fa-solid fa-bell mr-3"
                  style="cursor: pointer; font-size: 20px"
                ></i>
                <!-- <i class="fa-solid fa-bell-slash"></i> -->
                <b-button
                  variant="success"
                  size="sm"
                  class="ml-2"
                  style="
                    background: #376f7e !important;
                    border-color: #376f7e !important;
                  "
                  @click="toggle"
                  ref="BotaoMenu"
                >
                  {{ label_botao_menu }}
                </b-button>
                <div
                  class="d-flex justify-content-center"
                  style="position: relative"
                >
                  <Menu
                    ref="menu_togglable"
                    id="overlay_menu"
                    :model="timeline_filter_options"
                    style="
                      position: absolute;
                      transform: translateY(20px);
                      z-index: 99;
                      right: 0;
                    "
                    v-if="visible_menu_opcoes"
                  />
                </div>
              </div>
            </div>
            <div class="card-toolbar pt-4 d-flex align-items-center">
              <div class="d-flex">
                <span class="mr-3"
                  ><i class="fas fa-arrow-up" style="color: #f37c3a"></i>
                  Envio</span
                >
                <span class="mr-3"
                  ><i class="fas fa-arrow-down" style="color: #376f7e"></i>
                  Resposta</span
                >
              </div>
              <div class="d-flex">
                <span class="mr-3"
                  ><i class="fas fa-times text-danger"></i> Erro</span
                >
                <span
                  ><i class="fas fa-stopwatch text-muted"></i> Agendado</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="timeline timeline-justified timeline-4 ml-2">
          <div class="timeline-bar"></div>
          <div class="timeline-items">
            <div
              v-for="(item, i) in filteredLista"
              :key="i"
              class="timeline-item"
              :class="[item.tipo]"
            >
              <div class="timeline-badge">
                <i
                  class="fas icon-xl"
                  :class="[
                    configuracoes[item.tipo].texto,
                    configuracoes[item.tipo].icone
                  ]"
                ></i>
              </div>
              <div class="timeline-label">
                <span
                  class="font-weight-bold"
                  :class="[configuracoes[item.tipo].texto]"
                  >{{ dataFormatada(item.data) }}</span
                >
              </div>

              <div
                class="d-flex justify-content-between timeline-content flex-column justify-content-start"
                style="gap: 0.5rem"
                :style="{
                  background: configuracoes[item.tipo].fundo,
                  color: configuracoes[item.tipo].texto
                }"
              >
                <div>
                  <template v-if="item.tipo == 'agendamento'">
                    <span class="font-weight-bold">AGENDAMENTO</span>
                    <br />
                  </template>
                  <span class="font-weight-bold"
                    >{{ item.tipo == "erro" ? "Erro" : "Modelo" }}:</span
                  >
                  {{ item.campanha }} <br />
                  <span class="font-weight-bold">Campanha:</span>
                  {{ item.modelo }} <br />
                  <template v-if="configuracoes[item.tipo].textoQuantidade">
                    <span class="font-weight-bold">Quantidade:</span>
                    {{ item.quantidade }}
                    {{ configuracoes[item.tipo].textoQuantidade }}
                  </template>
                  <template v-if="item.qtd_enviado_erro > 0">
                    <br />
                    <span class="font-weight-bold">Envios com erro:</span>
                    {{ item.qtd_enviado_erro }} envios
                  </template>
                </div>
                <div v-if="item.tipo == 'erro' && item.agendada_id">
                  <b-button
                    variant="warning"
                    size="sm"
                    @click="executarCampanhaNovamente(item)"
                    style="
                      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                      white-space: nowrap;
                    "
                  >
                    Reenviar Mensagens Erro</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import moment from "moment";
import Swal from "sweetalert2";
import API_LINKS from "../automacao/api.links";
import ApiService from "@/core/services/api.service";
import Tooltip from "./Tooltip.vue";
import Menu from "primevue/menu";

export default {
  props: ["titulo", "quantidade", "lista", "carregando", "timelineHeight"],
  components: { Tooltip, Menu },
  computed: {
    filteredLista() {
      if (this.label_botao_menu == "Todos") {
        return this.lista;
      } else if (this.label_botao_menu == "Envio") {
        return this.lista.filter((item) => item.tipo == "envio");
      } else if (this.label_botao_menu == "Resposta") {
        return this.lista.filter((item) => item.tipo == "recebimento");
      } else if (this.label_botao_menu == "Erro") {
        return this.lista.filter((item) => item.tipo == "erro");
      } else if (this.label_botao_menu == "Agendado") {
        return this.lista.filter((item) => item.tipo == "agendamento");
      }
      return this.lista;
    }
  },
  data() {
    return {
      label_botao_menu: "Todos",
      visible_menu_opcoes: false,
      timeline_filter_options: [
        {
          label: "Todos",
          command: () => {
            this.label_botao_menu = "Todos";
            this.$refs.BotaoMenu.style.background = "#366f7e";
            this.$refs.BotaoMenu.style.borderColor = "#366f7e";
            this.closeMenu();
          }
        },
        {
          label: "Envio",
          command: () => {
            this.label_botao_menu = "Envio";
            this.$refs.BotaoMenu.style.background = "#f37c3a";
            this.$refs.BotaoMenu.style.borderColor = "#f37c3a";
            this.closeMenu();
          }
        },
        {
          label: "Resposta",
          command: () => {
            this.label_botao_menu = "Resposta";
            this.$refs.BotaoMenu.style.background = "#376f7e";
            this.$refs.BotaoMenu.style.borderColor = "#376f7e";
            this.closeMenu();
          }
        },
        {
          label: "Erro",
          command: () => {
            this.label_botao_menu = "Erro";
            this.$refs.BotaoMenu.style.background = "#f64e60";
            this.$refs.BotaoMenu.style.borderColor = "#f64e60";
            this.closeMenu();
          }
        },
        {
          label: "Agendado",
          command: () => {
            this.label_botao_menu = "Agendado";
            this.$refs.BotaoMenu.style.background = "#B5B5C3";
            this.$refs.BotaoMenu.style.borderColor = "#B5B5C3";
            this.closeMenu();
          }
        }
      ],
      configuracoes: {
        envio: {
          texto: "#fff",
          fundo: "rgba(243, 124, 58, 0.8)",
          textoQuantidade: "envios",
          icone: "fa-arrow-up"
        },
        recebimento: {
          texto: "#fff",
          fundo: "#376f7e",
          textoQuantidade: "respostas",
          icone: "fa-arrow-down"
        },
        erro: {
          texto: "#fff",
          fundo: "#f64e60",
          textoQuantidade: "notificações",
          icone: "fa-times"
        },
        agendamento: {
          texto: "#000",
          fundo: "#F3F6F9",
          textoQuantidade: "",
          icone: "fa-stopwatch"
        }
      }
    };
  },
  methods: {
    closeMenu() {
      this.visible_menu_opcoes = false;
    },
    toggle() {
      this.visible_menu_opcoes = !this.visible_menu_opcoes;
    },
    dataFormatada(data) {
      return moment(data).format("DD/MM/YYYY HH[H]");
    },
    executarCampanhaNovamente(item) {
      const agendada_id = item.agendada_id;
      Swal.fire({
        title: "Deseja tentar novamente?",
        text: "A campanha será enviada novamente.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, tentar novamente!",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.value) {
          ApiService.post(API_LINKS.campanha + "/tentar-novamente", {
            agendada_id
          }).then((response) => {
            Swal.fire(
              "Tentativa realizada!",
              "A campanha foi enviada novamente.",
              "success"
            ).then(() => {
              window.location.reload();
            });
          });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.timeline.timeline-4:after {
  /* top: -500px !important; */
}

.timeline.timeline-4.timeline-justified .timeline-items {
  .timeline-item:after {
    margin-right: -37px !important;
  }
  .timeline-item.recebimento:after {
    border-right-color: #376f7e !important;
  }
  .timeline-item.erro:after {
    border-right-color: #f64e60 !important;
  }
  .timeline-item.envio:after {
    border-right-color: rgba(243, 124, 58, 0.8) !important;
  }
  .timeline-item.agendamento:after {
    border-right-color: #f3f6f9 !important;
  }
}

.timeline.timeline-4 .timeline-items .timeline-item .timeline-badge {
  width: 30px;
  height: 30px;
  top: 3.2em;
}
.timeline.timeline-4.timeline-justified
  .timeline-items
  .timeline-item
  .timeline-badge {
  left: -10px;
}
.card {
  transition: 0.3s ease;
  &:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
}
</style>
