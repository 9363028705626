<template>
  <div class="card card-custom h-100" id="cardGrafico">
    <v-overlay :value="carregando" :absolute="true">
      <v-progress-circular indeterminate size="32" color="#356a7c" width="2"
        ><v-progress-circular
          indeterminate
          size="24"
          color="#ef720f"
          width="2"
          rotate="180"
        ></v-progress-circular
      ></v-progress-circular>
    </v-overlay>

    <div class="card-body p-0 d-flex flex-column">
      <div
        class="d-flex align-items-center justify-content-between card-spacer"
      >
        <div class="d-flex flex-column">
          <div class="d-flex align-items-center">
            <h5 class="m-0 mr-3">Mensagens</h5>
            <Tooltip
              message="O gráfico e a legenda demonstram as mensagens recebidas, as mensagens enviadas e os erros referentes ao período de tempo selecionado."
            />
          </div>
          <span
            @click.prevent="abrirRelatorio()"
            style="text-transform: capitalize; cursor: pointer; color: #b5b5c3"
          >
            ver relatório
          </span>
        </div>
        <div class="d-flex align-items-center">
          <slot></slot>
          <div>
            <b-button
              variant="success"
              size="sm"
              class="ml-2"
              style="
                background: #376f7e !important;
                border-color: #376f7e !important;
              "
              @click="toggle"
              ref="BotaoMenu"
            >
              {{ label_botao_menu }}
            </b-button>
            <div
              class="d-flex justify-content-center"
              style="position: relative"
              v-if="visible_menu_opcoes"
            >
              <Menu
                ref="menu_togglable"
                id="overlay_menu"
                :model="messageChannelFilterOptions"
                style="
                  position: absolute;
                  transform: translateY(4px);
                  z-index: 99;
                  right: 0;
                "
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-column align-items-center w-100 h-100 pb-3"
        style="justify-content: space-evenly"
      >
        <apexchart
          class="card-rounded-bottom w-100"
          :options="opcoesGrafico"
          :series="series"
          type="area"
          width="100%"
        ></apexchart>
        <div
          class="d-flex align-items-center justify-content-center"
          style="gap: 3rem"
        >
          <div class="d-flex flex-column align-items-center">
            <i
              class="fa-solid fa-arrow-up"
              style="font-size: 2rem; color: #f37c3a"
            ></i>
            <div class="message-number">
              {{ enviosLineChart }}
            </div>
            <div class="text-muted">Envios</div>
          </div>
          <div class="d-flex flex-column align-items-center">
            <i
              class="fa-solid fa-arrow-down"
              style="font-size: 2rem; color: #376f7e"
            ></i>
            <div class="message-number">
              {{ recebimentosLineChart }}
            </div>
            <div class="text-muted">Recebimentos</div>
          </div>
          <div class="d-flex flex-column align-items-center">
            <i
              class="fa-solid fa-times"
              style="font-size: 2rem; color: #f64e60"
            ></i>
            <div class="message-number">
              {{ errosLineChart }}
            </div>
            <div class="text-muted">Erros</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Tooltip from "./Tooltip.vue";
import Menu from "primevue/menu";

export default {
  components: { Tooltip, Menu },
  props: {
    total_aglutinado_series: {
      required: true
    },
    series_whatsapp: {
      required: true
    },
    series_sms: {
      required: true
    },
    total_aglutinado: {
      required: true
    },
    total_whatsapp: {
      required: true
    },
    total_sms: {
      required: true
    },
    titulo: {
      required: true
    },
    tipo: {
      required: true
    },
    periodo: {
      required: true
    },
    carregando: {
      required: true
    }
  },
  name: "grafico",
  data() {
    return {
      series: [],
      opcoesGrafico: {},
      visible_menu_opcoes: false,
      label_botao_menu: "WhatsApp"
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    messageChannelFilterOptions() {
      const options = [
        {
          label: "Todos",
          command: this.clickAll
        },
        {
          label: "WhatsApp",
          command: this.clickWpp
        },
        {
          label: "SMS",
          command: this.clickSMS
        }
      ];
      if (this.total_sms == 0) {
        options.splice(0, 1);
        options.splice(1, 2);
      }
      if (this.total_whatsapp == 0) {
        options.splice(0, 2);
      }
      return options;
    },
    enviosLineChart() {
      if (this.label_botao_menu == "WhatsApp") {
        const envios = this.series_whatsapp.find(
          (serie) => serie.name === "Envios"
        );
        if (envios) {
          let sum = 0;
          envios.data.forEach((envio) => {
            sum += +envio.y;
          });
          return sum;
        }
        return 0;
      } else if (this.label_botao_menu == "SMS") {
        const envios = this.series_sms.find((serie) => serie.name === "Envios");
        if (envios) {
          let sum = 0;
          envios.data.forEach((envio) => {
            sum += +envio.y;
          });
          return sum;
        }
        return 0;
      }
      const envios = this.total_aglutinado_series.find(
        (serie) => serie.name === "Envios"
      );
      if (envios) {
        let sum = 0;
        envios.data.forEach((envio) => {
          sum += +envio.y;
        });
        return sum;
      }
      return 0;
    },
    recebimentosLineChart() {
      if (this.label_botao_menu == "WhatsApp") {
        const recebimentos = this.series_whatsapp.find(
          (serie) => serie.name === "Recebimentos"
        );
        if (recebimentos) {
          let sum = 0;
          recebimentos.data.forEach((recebimento) => {
            sum += +recebimento.y;
          });
          return sum;
        }
        return 0;
      } else if (this.label_botao_menu == "SMS") {
        const recebimentos = this.series_sms.find(
          (serie) => serie.name === "Recebimentos"
        );
        if (recebimentos) {
          let sum = 0;
          recebimentos.data.forEach((recebimento) => {
            sum += +recebimento.y;
          });
          return sum;
        }
        return 0;
      }
      const recebimentos = this.total_aglutinado_series.find(
        (serie) => serie.name === "Recebimentos"
      );
      if (recebimentos) {
        let sum = 0;
        recebimentos.data.forEach((recebimento) => {
          sum += +recebimento.y;
        });
        return sum;
      }
      return 0;
    },
    errosLineChart() {
      if (this.label_botao_menu == "WhatsApp") {
        const erros = this.series_whatsapp.find(
          (serie) => serie.name === "Erros"
        );
        if (erros) {
          let sum = 0;
          erros.data.forEach((erro) => {
            sum += +erro.y;
          });
          return sum;
        }
        return 0;
      } else if (this.label_botao_menu == "SMS") {
        const erros = this.series_sms.find((serie) => serie.name === "Erros");
        if (erros) {
          let sum = 0;
          erros.data.forEach((erro) => {
            sum += +erro.y;
          });
          return sum;
        }
        return 0;
      }
      const erros = this.total_aglutinado_series.find(
        (serie) => serie.name === "Erros"
      );
      if (erros) {
        let sum = 0;
        erros.data.forEach((erro) => {
          sum += +erro.y;
        });
        return sum;
      }
      return 0;
    }
  },
  mounted() {
    this.opcoesGrafico = {
      colors: ["#f64e60", "#376f7e", "#f37c3a"],
      chart: {
        height: 200,
        type: "area",
        stacked: true,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      fill: {
        type: "gradient",
        gradient: {
          opacityFrom: 1,
          opacityTo: 1
        }
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth"
      },
      xaxis: {
        type: "datetime",
        labels: {
          show: false
        },
        tooltip: {
          enabled: false
        }
      },
      yaxis: {
        labels: {
          formatter: function (valor) {
            return valor.toFixed(0);
          },
          oposite: true
        }
      }
    };
    if (this.total_whatsapp != 0 && this.total_sms != 0) {
      this.clickAll();
    } else if (this.total_whatsapp == 0 && this.total_sms > 0) {
      this.clickSMS();
    } else {
      this.updateApexWpp();
    }
  },
  methods: {
    updateFromPicker() {
      if (this.label_botao_menu == "WhatsApp") {
        this.updateApexWpp();
      } else if (this.label_botao_menu == "SMS") {
        this.updateApexSMS();
      } else {
        this.updateApexAll();
      }
    },
    clickAll() {
      this.label_botao_menu = "Todos";
      this.$refs.BotaoMenu.style.background = "#366f7e";
      this.$refs.BotaoMenu.style.borderColor = "#366f7e";
      this.updateApexAll();
      this.closeMenu();
    },
    clickWpp() {
      this.label_botao_menu = "WhatsApp";
      this.$refs.BotaoMenu.style.background = "#00a884";
      this.$refs.BotaoMenu.style.borderColor = "#00a884";
      this.updateApexWpp();
      this.closeMenu();
    },
    clickSMS() {
      this.label_botao_menu = "SMS";
      this.$refs.BotaoMenu.style.background = "#f37c3a";
      this.$refs.BotaoMenu.style.borderColor = "#f37c3a";
      this.updateApexSMS();
      this.closeMenu();
    },
    abrirRelatorio() {
      this.$router.push({
        name: "relatorio-detalhado",
        params: { periodo: this.periodo }
      });
    },
    closeMenu() {
      this.visible_menu_opcoes = false;
    },
    toggle() {
      this.visible_menu_opcoes = !this.visible_menu_opcoes;
    },
    updateApexWpp() {
      this.series = JSON.parse(JSON.stringify(this.series_whatsapp));
    },
    updateApexAll() {
      this.series = JSON.parse(JSON.stringify(this.total_aglutinado_series));
    },
    updateApexSMS() {
      this.series = JSON.parse(JSON.stringify(this.series_sms));
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  transition: 0.3s ease;
  &:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
}
.message-number {
  font-weight: 600;
  font-size: 1.5rem;
}
.text-muted {
  color: #434658 !important;
}
</style>
