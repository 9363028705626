var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card card-custom"},[_c('div',{staticClass:"card-body p-0"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between card-spacer flex-grow-1"},[_c('div',{staticClass:"d-flex flex-column w-100"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('h5',{staticClass:"m-0 mr-3"},[_vm._v("Estatísticas de Créditos - Mensal")]),_c('Tooltip',{attrs:{"message":"O gráfico de estatísticas mensais representa os créditos do mês atual,  demonstrando o saldo utilizado e limite de créditos."}})],1),_c('div',[_c('b-button',{ref:"BotaoMenu",staticClass:"ml-2",staticStyle:{"background":"#376f7e !important","border-color":"#376f7e !important"},attrs:{"variant":"success","size":"sm"},on:{"click":_vm.toggle}},[_vm._v(" "+_vm._s(_vm.label_botao_menu)+" ")]),(_vm.visible_menu_opcoes)?_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"position":"relative"}},[_c('Menu',{ref:"menu_togglable",staticStyle:{"position":"absolute","transform":"translateY(4px)","z-index":"99","right":"0"},attrs:{"id":"overlay_menu","model":_vm.messageChannelFilterOptions}})],1):_vm._e()],1)]),_c('div',{staticClass:"mt-2 mb-4 message-info-container"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('div',{staticClass:"custom-badge",class:_vm.creditoPorcentagem > 0.6
                  ? 'green'
                  : _vm.creditoPorcentagem > 0.15
                  ? 'orange'
                  : 'red'}),_vm._v(" Saldo Utilizado ("+_vm._s(_vm.dados.limiteCredito - _vm.canal.saldo)+") ")]),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('div',{staticClass:"custom-badge gray"}),_vm._v(" Limite de créditos ("+_vm._s(_vm.dados.limiteCredito)+") - "+_vm._s(_vm.plano)+" ")])]),_c('b-progress',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"w-100 mt-5",staticStyle:{"height":"24px","border-radius":"12px"},attrs:{"title":'Saldo restante: ' + _vm.canal.saldo,"value":_vm.dados.limiteCredito - _vm.canal.saldo,"max":_vm.dados.limiteCredito,"show-value":false,"variant":_vm.creditoPorcentagem > 0.6
              ? _vm.cor_inicial_barra
              : _vm.creditoPorcentagem > 0.15
              ? 'warning'
              : 'danger'}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }